const errorNamedCodes = {
    'certificate password incorrect' : 'Неверный пароль',
    'certificate owner not found in database' : 'Сертификат не соответствует ни одному подписанту для заданной сессии',
    'document has already been signed by the client with this IIN' : 'Документ уже подписан клиентом',
    'no certificate found for this login': 'По данному логину не найден сертификат',
    'no certificate found for this user': 'По данному пользователю не найден сертификат',
    'uniqueness error: the given login is already present in the database': 'Указанный логин уже существует',
    'certificate has expired': 'Сертификат не действителен',
    'the certificate does not match any signer for the given session': 'Вы не являетесь подписантом для данной сессии',
    'signing file not found': 'Подписываемый файл не найден',
    'invalid certificate type': 'Выберите ключ для Подписания',
    'document already approved by user with this iin/bin': 'Данный пользователь уже выбрал решение по данному документу',
    'signer not found': 'Подписант не найден',
    'certificate is revoked': 'Сертификат отозван - Попробуйте другой',

    'invalid_cert': 'Неверный тип ключа',
    'session_canceled': 'Сессия аннулирована',
	'session_draft': 'Документ не согласован',
	'session_signed': 'Документ уже полностью подписан',

	'cert_password_incorrect': 'Неверный пароль сертификата',
	'cert_invalid': 'Сертификат не действителен',
	'cert_revoked': 'Сертификат отозван',
	'cert_expired': 'Истек срок действия сертификата',
	'invalid_signer': 'Неверный подписант',
	'signed': 'Вы уже подписали данный документ',
    'download_failed': 'Не удалось сохранить документ',
    'session_not_found': 'Сессия не найдена',
    'out_of_turn': 'Вы пытаетесь подписать вне очереди',
    'tech_error': 'Техническая ошибка, Просим повторить попытку позднее.',
    'service_unavailable': 'Техническая ошибка, Просим повторить попытку позднее.',
    'invalid_approver': 'Неверный согласующий',
    'expired_cert': 'Истек срок действия сертификата'
};

export function getErrorText(errorNamedCode) {
    if (errorNamedCode in errorNamedCodes) {
        return errorNamedCodes[errorNamedCode];
    }

    // return errorNamedCodes.UnhandledException;
}
